import { formatRange } from '@medplum/core';
import { Range } from '@allaymd/apptypes';

export interface RangeDisplayProps {
  value?: Range;
}

export function RangeDisplay(props: RangeDisplayProps): JSX.Element | null {
  return <>{formatRange(props.value)}</>;
}
