import { Title } from '@mantine/core';
import { DescriptionList, DescriptionListEntry, useMedplum } from '@medplum/react';
import { getProjectId } from '../utils';
import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Project } from '@allaymd/apptypes';

export function ProjectDetailsPage(): JSX.Element {
  const medplum = useMedplum();
  const projectId = getProjectId(medplum);
  const [project, setProject] = useState<Project>();

  useEffect(() => {
    medplum.get(`admin/projects/${projectId}`)
      .then((result) => setProject(result.project))
      .catch((error) => showNotification({ color: 'red', title: 'Error', message: error.message }));
  }, [medplum, projectId]);

  return (
    <>
      <Title>Details</Title>
      <DescriptionList>
        <DescriptionListEntry term="ID">{project?.id}</DescriptionListEntry>
        <DescriptionListEntry term="Name">{project?.name}</DescriptionListEntry>
      </DescriptionList>
    </>
  );
}
